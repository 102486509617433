import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Image from "../components/image"
import { FaChevronRight } from "react-icons/fa"
import Helmet from "react-helmet"
import {ImageFilename} from '../utils/common'

const brandPage = ({ data, location, pageContext: { brand_id } }) => {
	const brand = data.brandsYaml
	const allTypesYaml = data.allTypesYaml
	const allSizesYaml = data.allSizesYaml
	const allSubbrandsYaml = data.allSubbrandsYaml
	let description = `${brand.description} オムツ1枚あたりの最安値！`
	let preTitle = ``

	// パンくずリストの構造化データ
	const jsonLd = {
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement": [{
			"@type": "ListItem",
			"position": 1,
			"name": "トップ",
			"item": "https://hula-la.com/"
		},{
			"@type": "ListItem",
			"position": 2,
			"name": brand.description,
			"item": "https://hula-la.com"+location.pathname
		}]
	};

	// allContentfulItemsを1枚当たりの価格が安い順に並び替える(Graphqlのソートだと100円以上の場合に並び替えが正しくできないため数値のソートを自前で行う)
	data.allContentfulItems.edges.sort(function(a,b){
		if((!a.node.itemList.length) && (!b.node.itemList.length)){
			return 0
		}
		// itemList無ければ後ろへ並べる
		else if((!a.node.itemList.length) && (b.node.itemList.length)){
			return 1
		}
		else if((a.node.itemList.length) && (!b.node.itemList.length)){
			return -1
		}
		return a.node.itemList[0].per - b.node.itemList[0].per
	})	

	return (
  <Layout>
		<nav className="breadcrumb is-small" aria-label="breadcrumbs" style={{marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>
			<ul>
				<li><Link to="/">トップ</Link></li>
				<li className="is-active"><Link to="" aria-current="page">{brand.description}</Link></li>
			</ul>
		</nav>		
			<>
				<Helmet>
     			<script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
   			</Helmet>
				{allTypesYaml.edges.map(edge => {
					const type = edge.node
					const item = data.allContentfulItems.edges.find(n => {
						return n.node.typeId === type.cid
					})
					if(!item) return <></>
					description += `${type.description}タイプ `
					const sizeStr = ((type.cid !== 'wipe') && (type.cid !== 'handwipe'))? 'サイズ' : 'タイプ' 
					const itemStr = ((type.cid !== 'wipe') && (type.cid !== 'handwipe'))? 'オムツ' : type.description
					return (
						<>
						<div className="content" style={{marginTop: `0.5rem`}}>
							<h3 className="title is-6" style={{marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>{brand.description} {type.description}</h3>
							<div className="columns is-multiline is-gapless">
								{allSizesYaml.edges.map(edge => {
			            const size = edge.node
									const item = data.allContentfulItems.edges.find(n => {
										return n.node.typeId === type.cid && n.node.sizeId === size.cid && n.node.itemList.length !== 0
									})
									if(!item) return <></>
									if(!item.node.itemList.length) return <></>
									const filename = ImageFilename(brand.cid, type.cid, size.cid, item.node.subbrandId, item.node.itemList[0])
									description += `${size.description}${item.node.itemList[0].per.toFixed(1)}円 `
									preTitle += preTitle === `` ? `${item.node.itemList[0].per.toFixed(1)}円/枚` : ``
									let most_min = 100
									let most_max = 0
									let most_unit = ''
									allSubbrandsYaml.edges.forEach(n => {
										if (n.node.brand_id === brand.cid) {
											n.node.types.forEach(m => {
												if(m.type_id === type.cid) {
													m.sizes.forEach(l => {
														if(l.size_id === size.cid) {
															most_min = l.min < most_min ? l.min : most_min
															most_max = l.max > most_max ? l.max : most_max
															most_unit = l.unit
														}
													})
												}
											})
										}
									})
									const min = most_min !== 0 ? `${most_min}${most_unit}` : ``
									const max = `${most_max}${most_unit}`
									let min_max = min + '～' + max
									if((most_min === 0) && (most_max === 0))
									{
										min_max = '-'
									}		
									return (
										<div className="column is-half">
											<Link to={`/${brand.cid}_${type.cid}_${size.cid}.html`}>
												<div className="card">
													<div className="card-content" style={{padding: `0.8rem`}}>
														<div className="media">
															<div className="media-left">
																<Image filename={filename} alt={`${brand.description}${type.description}タイプ${size.description}${sizeStr}の${itemStr}パッケージ画像`}　/>
															</div>
															<div className="media-content">
																<div className="content">
																		<p>
																			<span style={{fontSize:`0.7rem`}}><strong>{size.description}{sizeStr}</strong></span>
																			<br />
																			<span style={{color: `#ff0000`}} className="is-size-4">&nbsp;{item.node.itemList[0].per.toFixed(1)}<span style={{fontSize:`0.7rem`}}> 円/枚</span> ～</span>
																			<br />
																			<span className="tag" style={{fontSize:`0.6rem`}}>{type.description}</span>
																			{ min_max !== '-' && (
																				<span className="tag" style={{fontSize:`0.6rem`}}>{min_max}</span>
																			)}
																		</p>
																	</div>
																</div>
															<div className="media-right">
																<span className="icon" style={{height: `2.5em`}}>
																	<FaChevronRight />
																</span>
															</div>
														</div>
													</div>
												</div>
											</Link>
										</div>
									)
								})}
							</div>
						</div>
						<br></br>
						</>
					)
				})}
			</>
		<Seo title={`${brand.description}`} description={description} canonical={location.pathname} />
  </Layout>
	)
}

export const query = graphql`
	query($brand_id: String!) {
		brandsYaml(cid: { eq: $brand_id}) {
			cid
			description
		}
		allContentfulItems(filter: {brandId: { eq: $brand_id}}) {
			edges {
				node {
					brandId
					typeId
					sizeId
					subbrandId
					itemList {
						num
						shopId
						shop
						href
						per
						price
						sendPrice
						souryou
						txt
						point
					}
				}
			}
		}
		allTypesYaml {
			edges {
				node {
					cid
					description
				}
			}
		}
		allSizesYaml {
			edges {
				node {
					cid
					description
				}
			}
		}
		allSubbrandsYaml {
			edges {
				node {
					brand_id
					subbrand_id
					description
					types {
						type_id 
						sizes {
							size_id
							min
							max
							unit
							num	
						}
					}
				}
			}
		}  
	}
`

export default brandPage
